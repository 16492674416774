let randomRegistrarCall = function(trigger, targetContainer, parentContainer, cfErrorHandling){
    $.ajax({
        method: 'GET',
        url: trigger.data('random-registrar-url'),
        data: {
            limit: trigger.data('random-registrar-limit'),
            logo: trigger.data('random-registrar-with-logo')
        },
        success: function (response) {
            targetContainer.html(response);
        },
        error: function (response) {
            if(response.status === 522) {
                cfErrorHandling(response);
            }
            else {
                var errorMessageHtml = parentContainer
                    .find('.js-random-registrar-error-message')
                    .innerHTML;

                targetContainer.html(
                    errorMessageHtml
                );
            }
        }
    });
};

$(function () {
    $('.js-random-registrar-trigger').on('click', function (e) {
        $('.js-random-registrar-error-message-cf').fadeOut();
        let trigger = $(this);
        let parentContainer = trigger.closest('.js-random-registrar-container');
        let targetContainer = parentContainer.find('.js-random-registrar-list');

        e.preventDefault();
        // eslint-disable-next-line no-unused-vars
        let customCfErrorHandling = function(response){
            $('.js-random-registrar-error-message-cf').fadeIn();
        };
        if(trigger.length)
            randomRegistrarCall(trigger, targetContainer, parentContainer, customCfErrorHandling);
    });
});

$(function () {
    $('#advanced-search-trigger').on('click', function (e) {
        let trigger = $(this);
        let parentContainer = trigger.closest('.js-random-registrar-container');
        let icon = trigger.find('i');
        icon.toggleClass('fa-rotate-90');
        let filterRegistrarList = parentContainer.find('#filter-registrar-list');

        e.preventDefault();

        filterRegistrarList.slideToggle();
    });


});

$(document).ready(function(){
    let trigger = $('.registrar-list-plugin a.js-random-registrar-trigger');
    let parentContainer = trigger.closest('.js-random-registrar-container');
    let targetContainer = parentContainer.find('.js-random-registrar-list');
    // eslint-disable-next-line no-unused-vars
    let cfErrorHandlingCustom = function(response){
        // we do nothing here as it should be cached
    };
    if(trigger.length)
        randomRegistrarCall(trigger, targetContainer, parentContainer, cfErrorHandlingCustom);
});

