import WOW from "wowjs";
import "animate.css"
import Swiper, {Autoplay } from 'swiper';
import "swiper/swiper-bundle.css"

import "../css/helpers.css"
import '../css/style.css'
import '../css/custom.css'
import '../css/header.css'
import '../css/footer.css'
import '../css/plugins.css'

$(window).on('load', function () {

    $('#form_das').removeAttr('disabled');

    function das_callback(data, language='en') {
        let selector = "#das_result_" + data['status'];
        let selector_homepage = "#das_result_homepage_" + data['status'];
        if (data['status'] != "ERROR") {
            if(data['status'] == 'NOT_AVAILABLE')
            {
                $(".wordbreak").html(`<a href="https://whois.eurid.eu/${language}/search?domain=${data['name']}" class="btn-link">${data['name']}</a>`);
                $("#das_result_NOT_AVAILABLE a, #das_result_homepage_NOT_AVAILABLE a").attr("href", `https://whois.eurid.eu/${language}/search?domain=${data['name']}`);
            }
            else {
                $(".wordbreak").text(data['name']);
                if(data['status'] == 'AVAILABLE'){
                    $('div#das_result_homepage_AVAILABLE a').attr('href', `/register-a-eu-domain?domain=${data['name']}`);
                }
            }
        }
        $(".das_result").hide();
        $(selector).show().addClass('animated bounceIn');
        $(selector_homepage).show().addClass('animated bounceIn');
    }

    function submit_das_query(das_callback, language='en') {
        $.ajax({
            url: $("#form_das").attr('action'),
            context: document.body,
            method: 'GET',
            type: 'GET',
            data: $("#form_das").serialize(),
            success: function (data) {
                das_callback(data, language);
            },
            error: function(jqXHR) {
                if(jqXHR.status === 522)
                    das_callback({status: 'CF_ERROR'}, language);
                else
                    das_callback({status: 'ERROR'}, language);
            }
        });
    }

    $("#form_das:not([disabled])").submit(function (event) {
        event.preventDefault(); // Prevent the form from submitting via the browser
        if ($("#id_domain_name").val().trim() != "") {
            submit_das_query(das_callback,  $('#id_language').val());
        }
    });

    function getUrlVars() {
        let vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            // eslint-disable-next-line security/detect-object-injection
            vars[key] = value;
        });
        return vars;
    }

    // homepage field DAS
    let domain_name_filled = getUrlVars().domain || '';
    if (domain_name_filled) {
        $("#id_domain_name").val(domain_name_filled);
        submit_das_query(das_callback);
    }

    $(".selpicker").each(function(){
        $.fn.selectpicker.Constructor.BootstrapVersion = '3';
        let livesearch = false;
        if(!$(this).attr('data-live-search'))
            livesearch = $(this).find('option').length > 10;
        else
            livesearch = $(this).attr('data-live-search') == 'true';

        let mobile = false;
        if($(window).width() <= 767 && !!$(this).attr('data-mobile')){
            mobile = true;
        }
        let ariaLabel = $(this).attr('data-aria-label') || '';
        let nothingSelected = $(this).attr('data-nothing-selected') || '';
        $(this).selectpicker({noneSelectedText: nothingSelected, liveSearch: livesearch, 'style': '', mobile: mobile});
        if(ariaLabel)
            $(this).parent('div').find('button').attr('aria-label', ariaLabel);
    });

    // WHOIS Search
    $('.whois_item').click(function (e) {
        e.preventDefault();
        if ($('.top-whois-bar').is(':visible')) {
            $('.top-whois-bar').slideUp(300);
        }
        else {
            $('.top-whois-bar').slideDown(300).find('input[type=text]').focus();
        }
    });

    // Website search
    $('.loop-link a').click(function () {
        $('#popup_content_area').fadeIn(500).find('input[type=text]').focus();
    });
    $("#popup_content_area > .close_pop").click(function () {
        $("#popup_content_area").fadeOut("fast");
    });
    $('#popup_content_area').click(function(){
        $(this).fadeOut("fast");
    });

    $('#popup_content_area').keydown(function(e){
        if(e.keyCode === 27){
            $("#popup_content_area").fadeOut("fast");
        }
    });

    $('#pop_search h3').click(function(e){
        e.stopPropagation();
    });
    $('#pop_search form').click(function(e){
        e.stopPropagation();
    });

    // set clearfix for main nav bar collapse

    if($(window).width() <= 752){
        $(".main-nav").addClass("clearfix");
    }
    else{
        $(".main-nav").removeClass("clearfix");
    }
    if ($('.parsley').length > 0) {
        $.each($('.parsley'), function(i, instance) {
            $(instance).parsley({
                errorsWrapper: '<span class="parsley-errors-list animated bounceIn alert alert-danger arrow_box_danger arrow_box_danger_small"></span>',
                errorTemplate: '<span></span>',
                validateIfUnchanged: true,
                errorsContainer: function (el) {
                    return el.$element.siblings(".errors")?.length ? el.$element.siblings(".errors") : el.$element.parent().siblings('.errors');
                }
            }).on('form:validated', function (formInstance) {
                if (!formInstance.isValid()) {
                    if ($('.tab-content').length > 0) {
                        let current_tabpane = $(".error-tooltip:parent").closest('.tab-pane').attr("id");
                        if (current_tabpane) {
                            $("a[href='#" + current_tabpane + "']").tab('show');
                        }
                    }
                }
            });
        });

        window.Parsley.addValidator('phone', function (value) {
            return phoneNumber(value)
        }, 32)
            .addMessage('en', 'phone', 'Invalid phone number format');

        window.Parsley.addValidator('maxFileSize', {
            // file size in kB
            validateString: function (_value, maxSize, parsleyInstance) {
                if (!window.FormData) {
                    return true;
                }
                let files = parsleyInstance.$element[0].files;
                return files.length !== 1 || files[0].size <= maxSize * 1024;
            },
            requirementType: 'integer'
        });

        window.Parsley.addValidator('fileTypeEqual', {
            validateString: function (_value, fileType, parsleyInstance) {
                if (!window.FormData) {
                    return true;
                }
                let files = parsleyInstance.$element[0].files;
                return files[0].type === fileType;
            },
            requirementType: 'string'
        });

    }

});

$(window).resize(function(){

    if($(window).width() <= 752){
        $(".main-nav").addClass("clearfix");
    }
    else{
        $(".main-nav").removeClass("clearfix");
    }
});

$('.navbar-nav > li.dropdown').hover(function(e){
   var md = new MobileDetect(window.navigator.userAgent);
   if(!md.mobile()) {
       e.preventDefault();
       $(this).find('.dropdown-menu:not(.submenu)').dropdown('toggle');
   }
});

$('.navbar-nav > li.dropdown').click(function(e){
   var md = new MobileDetect(window.navigator.userAgent);
   if(md.mobile()) {
       e.stopPropagation();
       $(this).find('.dropdown-menu:not(.submenu)').dropdown('toggle');
   }
   else{
       if($(this).hasClass('children')){
           e.stopPropagation();
           e.preventDefault();
           $(this).find('span:not(.caret).desktop_view:not(.menu-title)').trigger("onclick");
       }
   }
});

$('.collapsible-sub-menu').click(function(e){
    e.stopPropagation();
    e.preventDefault();
    $(this).find('i').toggleClass("fa-rotate-90");
    let md = new MobileDetect(window.navigator.userAgent);
    if(md.mobile())
        $(this).next('ul').toggle();
    else
        $(this).dropdown('toggle');
});

$('.collapsible-sub-menu span.menu-title').click(function(e){
    let md = new MobileDetect(window.navigator.userAgent);
    if(!md.mobile() && !md.tablet()){
        e.stopPropagation();
    }
});



// first level
$('.navbar-nav > li.dropdown > a > span.caret').click(function(e){
   let md = new MobileDetect(window.navigator.userAgent);
   if(!md.mobile()) {
       e.preventDefault();
       e.stopPropagation();
       $(this).parent().find('span:not(.caret)').click();
   }
   else{
       e.preventDefault();
       e.stopPropagation();
       $(this).parent().dropdown('toggle');
   }
});

$('.navbar-nav ul.dropdown-menu li.dropdown.children').hover(function(e){
    let md = new MobileDetect(window.navigator.userAgent);
    if(!md.mobile()){
       e.preventDefault();
       e.stopPropagation();
       $(this).find('a.collapsible-sub-menu i').toggleClass('fa-rotate-90');
       $($(this).find('a.collapsible-sub-menu').attr('data-target-custom')).toggle();
    }
});

$('.navbar-nav ul.dropdown-menu li:not(.dropdown)').click(function(e){
    e.stopPropagation();
    e.preventDefault();
    window.location.replace($(this).find('a').attr('href'));
});

$('.navbar-nav .submenu a').click(function(){
   window.location.replace($(this).attr('href'));
});

$('.navbar-nav ul.dropdown-menu li.dropdown.children a.collapsible-sub-menu').click(function(e){
    let md = new MobileDetect(window.navigator.userAgent);
    if(!md.mobile()){
       e.preventDefault();
       e.stopPropagation();
       $(this).find('span').click();
    }
});
// bigger arrow on mobile
$(document).ready(function(){
    let md = new MobileDetect(window.navigator.userAgent);
    if(md.mobile()){
        $.each($('span.menu-icon'), function(ind, el){
            $(el).addClass('big-caret');
            $(el).addClass('mar-lft');
        });
        $.each($('i.menu-icon'), function(ind, el){
            $(el).addClass('fa-lg')
        })
    }
});

// Lazy loading
$(function () {
    $(document).ready(function () {
        $('.lazy').Lazy({
            scrollDirection: 'vertical',
            effect: 'fadeIn',
            visibleOnly: true
        });
        $('.lazy-carousel').on("slide.bs.carousel", function(ev) {
            let lazy;
            lazy = $(ev.relatedTarget).find("img[data-src]");
            lazy.each(function() {
                $(this).attr("src", $(this).data('src'));
            });
            lazy.removeAttr("data-src");
        });
        $('.story-modal.modal').on('shown.bs.modal', function(){
            // wait for fade effect to complete
            setTimeout(function(){}, 2500);

            let lazy = $(this).find("img.lazy");
            lazy.each(function() {
                $(this).attr("src", $(this).data('src'));
            });
            lazy.removeAttr("data-src");
        });
        $("#story-modal").on('hide.bs.modal', function () {
            $(this).find('iframe').attr('src', '');
        });
    });
});

function load_ajax_modal(id, youtube_id, url){
    $.ajax({
        type: 'GET',
        url: url+'?uuid=' + id,
        success: function(data){
            let modal_story = $('#story-modal');
            modal_story.attr('data-youtube-id', youtube_id);
            modal_story.html(data);
            $("#youtube_video_" + id).attr('src', youtube_id);
        }
    });
}

// tooltip
$(function () {
    $(document).ready(function () {
        $('[data-toggle="tooltip"], .add-tooltip').tooltip();
    });
});


$('#help_text_ars').next('button').click(function() {
    $('#help_text_ars').toggleClass('fade-out-text');
    $(this).find('i').toggleClass('fa-rotate-180');
});

// close whois when closing menu
$('.navbar-collapse').on('hide.bs.collapse ', function(){
    $('.top-whois-bar').slideUp(300);
});


$('.mobile_view').each(function(){
    let md = new MobileDetect(window.navigator.userAgent);
    if(!md.mobile()){
        $(this).hide();
    }
});

$('.tablet_view').each(function(){
    let md = new MobileDetect(window.navigator.userAgent);
    if(!md.tablet()){
        $(this).hide();
    }
});

$('.mobile_tablet_view').each(function(){
    let md = new MobileDetect(window.navigator.userAgent);
    if(!md.tablet() && !md.mobile()){
        $(this).hide();
    }
});

$('.desktop_view').each(function(){
    let md = new MobileDetect(window.navigator.userAgent);
    if(md.tablet() || md.mobile()){
        $(this).hide();
    }
});

// we do this it will export this as global
if (typeof window !== 'undefined') {
	window.load_ajax_modal = load_ajax_modal;
}

$(window).on('load', function () {
  $('#slider').flexslider({
      animation: "fade",
      controlNav: false,
      directionNav: true,
      // eslint-disable-next-line no-unused-vars
      start: function (slider) {
          $('body').removeClass('loading');
      }
  });
});

// language selector
$('.language_picker').on('change', function () {
    let url = $(this).val();
    if (url) {
        window.location = url; // redirect
    }
    return false;
});

// initialize highchart language
window.initHighChartLanguage = function(lang){
    moment.locale(lang);
    Highcharts.setOptions({
          lang: {
            months: moment.months(),
            weekdays: moment.weekdays(),
            shortWeekdays: moment.weekdaysShort(),
            shortMonths: moment.monthsShort(),
          }
        })
};

$('.toggle_content .collapse').on('show.bs.collapse', function(e){
    if ($(this).is(e.target)) {
        $('a[data-target="#'+$(this).attr("id")+'"').find('.fa-plus').removeClass('fa-plus').addClass('fa-minus')
    }
});

$('.toggle_content .collapse').on('hide.bs.collapse', function(e){
    if ($(this).is(e.target)) {
        $('a[data-target="#'+$(this).attr("id")+'"').find('.fa-minus').removeClass('fa-minus').addClass('fa-plus')
    }
});


$(document).ready(function(){
    new WOW.WOW({
        animateClass: 'animate__animated',
        live: false
    }).init();

    new Swiper('.swiper.swiper-eu-day', {
        modules: [Autoplay],
        spaceBetween: 30,
        autoHeight: true,
        slidesPerView: 3,
        breakpoints: {
            0: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 3
            }
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        }
    })
})
