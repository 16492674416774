// various dependencies for outside html and javascript files
import $ from 'jquery'
import 'jquery-ui'
import 'jquery-lazy'
import 'jquery.scrollto'
window.jQuery = window.$ = $;


import {CountUp} from 'countup.js'
window.CountUp = CountUp;

import 'chart.js'
import 'chartjs-plugin-datalabels'
import 'parsleyjs'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-select'
import "bootstrap-select/dist/css/bootstrap-select.min.css"
import 'flexslider'
import 'flexslider/flexslider.css'
import 'jquery-ui/ui/widget'
import "jquery-ui/ui/position.js"
import "jquery-ui/ui/data.js"
import "jquery-ui/ui/disable-selection.js"
import "jquery-ui/ui/focusable.js"
import "jquery-ui/ui/form-reset-mixin.js"
import "jquery-ui/ui/keycode.js"
import "jquery-ui/ui/labels.js"
import "jquery-ui/ui/scroll-parent.js"
import "jquery-ui/ui/tabbable.js"
import "jquery-ui/ui/unique-id.js"
import "jquery-ui/ui/widgets/draggable.js"
import "jquery-ui/ui/widgets/droppable.js"
import "jquery-ui/ui/widgets/resizable.js"
import "jquery-ui/ui/widgets/selectable.js"
import "jquery-ui/ui/widgets/sortable.js"
import "jquery-ui/ui/widgets/accordion.js"
import "jquery-ui/ui/widgets/autocomplete.js"
import "jquery-ui/ui/widgets/button.js"
import "jquery-ui/ui/widgets/checkboxradio.js"
import "jquery-ui/ui/widgets/controlgroup.js"
import "jquery-ui/ui/widgets/datepicker.js"
import "jquery-ui/ui/widgets/dialog.js"
import "jquery-ui/ui/widgets/menu.js"
import "jquery-ui/ui/widgets/mouse.js"
import "jquery-ui/ui/widgets/progressbar.js"
import "jquery-ui/ui/widgets/selectmenu.js"
import "jquery-ui/ui/widgets/slider.js"
import "jquery-ui/ui/widgets/spinner.js"
import "jquery-ui/ui/widgets/tabs.js"
import "jquery-ui/ui/widgets/tooltip.js"
import "jquery-ui/ui/effect.js"
import "jquery-ui/ui/effects/effect-blind.js"
import "jquery-ui/ui/effects/effect-bounce.js"
import "jquery-ui/ui/effects/effect-clip.js"
import "jquery-ui/ui/effects/effect-drop.js"
import "jquery-ui/ui/effects/effect-explode.js"
import "jquery-ui/ui/effects/effect-fade.js"
import "jquery-ui/ui/effects/effect-fold.js"
import "jquery-ui/ui/effects/effect-highlight.js"
import "jquery-ui/ui/effects/effect-puff.js"
import "jquery-ui/ui/effects/effect-pulsate.js"
import "jquery-ui/ui/effects/effect-scale.js"
import "jquery-ui/ui/effects/effect-shake.js"
import "jquery-ui/ui/effects/effect-size.js"
import "jquery-ui/ui/effects/effect-slide.js"
import "jquery-ui/ui/effects/effect-transfer.js"


import 'jquery-ui/themes/base/all.css'
import 'jquery-sticky'

import './jquery-jvectormap-2.0.5.min'
import './jquery-jvectormap-world-mill'
import './jquery-jvectormap-europe'

import '../css/fonts_google.css'
import '../css/jquery-jvectormap-2.0.5.css'

import MobileDetect from 'mobile-detect';

window.MobileDetect = MobileDetect;

import Highcharts from 'highcharts';
window.Highcharts = Highcharts;

// parsley languages
import 'parsleyjs/dist/i18n/bg.js'
import 'parsleyjs/dist/i18n/es.js'
import 'parsleyjs/dist/i18n/cs.js'
import 'parsleyjs/dist/i18n/da.js'
import 'parsleyjs/dist/i18n/de.js'
import 'parsleyjs/dist/i18n/et.js'
import 'parsleyjs/dist/i18n/el.js'
import 'parsleyjs/dist/i18n/en.js'
import 'parsleyjs/dist/i18n/fr.js'
import 'parsleyjs/dist/i18n/hr.js'
import 'parsleyjs/dist/i18n/it.js'
import 'parsleyjs/dist/i18n/lv.js'
import 'parsleyjs/dist/i18n/lt.js'
import 'parsleyjs/dist/i18n/hu.js'
import 'parsleyjs/dist/i18n/nl.js'
import 'parsleyjs/dist/i18n/pl.js'
import 'parsleyjs/dist/i18n/pt-pt.js'
import 'parsleyjs/dist/i18n/ro.js'
import 'parsleyjs/dist/i18n/sk.js'
import 'parsleyjs/dist/i18n/sl.js'
import 'parsleyjs/dist/i18n/fi.js'
import 'parsleyjs/dist/i18n/sv.js'
// import extra
import 'parsleyjs/dist/i18n/cs.extra.js'
import 'parsleyjs/dist/i18n/de.extra.js'
import 'parsleyjs/dist/i18n/el.extra.js'
import 'parsleyjs/dist/i18n/en.extra.js'
import 'parsleyjs/dist/i18n/fr.extra.js'
import 'parsleyjs/dist/i18n/hr.extra.js'
import 'parsleyjs/dist/i18n/it.extra.js'
import 'parsleyjs/dist/i18n/lv.extra.js'
import 'parsleyjs/dist/i18n/lt.extra.js'
import 'parsleyjs/dist/i18n/hu.extra.js'
import 'parsleyjs/dist/i18n/nl.extra.js'
import 'parsleyjs/dist/i18n/ro.extra.js'
import 'parsleyjs/dist/i18n/sk.extra.js'
import 'parsleyjs/dist/i18n/sl.extra.js'
import 'parsleyjs/dist/i18n/fi.extra.js'
import 'parsleyjs/dist/i18n/sv.extra.js'
// missing mt and ga (how surprising)
